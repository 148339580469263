












































































































import { JWTModule } from "@/store/modules";
import { Component, Vue } from "vue-property-decorator";
import { ConfirmResetPassword } from "@/store/models/jwt";

@Component
export default class PasswordReset extends Vue {
  payload: ConfirmResetPassword = {} as ConfirmResetPassword;

  isConfirmPwd = true;
  isPwd = true;

  created() {
    this.payload.uid = this.$route.params.uid;
    this.payload.token = this.$route.params.token;
  }

  async onSubmit() {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    JWTModule.confirmResetPassword(this.payload).then((response) => {
      if (response.message) {
        this.$snotify.success(response.message);
      }
      this.$router.push({ name: "NewHome" });
    });
  }
}
